import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid2"
import Typography from "@mui/material/Typography"
import * as React from "react"
import HomepageOutline from "./HomepageOutline"

import {
    BracketsCurly,
    CloudSlash,
    FastForward,
    FileVideo,
    Hash,
    Lightning,
    ReceiptX,
    ShieldCheck,
    Waveform,
} from "@phosphor-icons/react"

export default function Homepage({ theme }) {
    return (
        <HomepageOutline theme={theme}>
            <Typography variant="h4" sx={{ mb: 2 }}>
                Effortlessly organize recordings and interviews.
            </Typography>
            <Typography variant="h6" sx={{ mb: 2 }}>
                Tag important moments with time-coded notes.
                Build a topic index to connect the dots across all your sources.
                Then add fast, accurate, automatic transcription from $2 per hour &mdash; without troublesome subscriptions.
            </Typography>

            <Box
                component="img"
                sx={{
                    width: '100%'
                }}
                alt="Screenshot"
                src="/img/screenshot.png"
            />

            <Typography variant="h5" sx={{ mt: 12 }}>
                <b>Smart tools to help humans manually review audio and video.</b>
            </Typography>
            <Typography variant="h6">
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid size={{ xs: 12, sm: 4 }} sx={{ mb: 2 }}>
                        <Typography>
                            <Waveform size={32} />
                        </Typography>
                        <Typography variant="button">Waveform & transcript</Typography>
                        <br />
                        Quickly navigate across your recordings
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }} sx={{ mb: 2 }}>
                        <Typography>
                            <FastForward size={32} />
                        </Typography>
                        <Typography variant="button">Time-coded notes</Typography>
                        <br />
                        Immediately jump back to crucial moments
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }} sx={{ mb: 2 }}>
                        <Typography>
                            <Hash size={32} />
                        </Typography>
                        <Typography variant="button">Tags & topic index</Typography>
                        <br />
                        Synthesise themes over all your material
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }} sx={{ mb: 2 }}>
                        <Typography>
                            <FileVideo size={32} />
                        </Typography>
                        <Typography variant="button">mp3, m4a, mov, mp4, wav...</Typography>
                        <br />
                        Supports recordings in common audio and video file formats
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }} sx={{ mb: 2 }}>
                        <Typography>
                            <CloudSlash size={32} />
                        </Typography>
                        <Typography variant="button">Local Storage</Typography>
                        <br />
                        Your data stays on your device. Back it up with git or Dropbox.
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }} sx={{ mb: 2 }}>
                        <Typography>
                            <BracketsCurly size={32} />
                        </Typography>
                        <Typography variant="button">It computes</Typography>
                        <br />
                        Simple JSON file format. Roll your own analyses with tools like jq.
                    </Grid>
                </Grid>
            </Typography>

            <Typography variant="h5" sx={{ mt: 12 }}>
                <b>Transcription without subscription.</b>
            </Typography>
            <Typography variant="h6">
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid size={{ xs: 12, sm: 4 }} sx={{ mb: 2 }}>
                        <Typography>
                            <ReceiptX size={32} />
                        </Typography>
                        <Typography variant="button">No sign-up required</Typography>
                        <br />
                        Just pre-pay by the minute. We'll send you an access code to use on any
                        device.
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }} sx={{ mb: 2 }}>
                        <Typography>
                            <Lightning size={32} />
                        </Typography>
                        <Typography variant="button">No monthly limits</Typography>
                        <br />
                        Transcribe as much as you like, whenever you like.
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }} sx={{ mb: 2 }}>
                        <Typography>
                            <ShieldCheck size={32} />
                        </Typography>
                        <Typography variant="button">No data harvesting</Typography>
                        <br />
                        We delete your media and transcription from our servers soon after returning
                        it to you.
                    </Grid>
                </Grid>
            </Typography>

            <Typography variant="h5" sx={{ mt: 8, pb: 8 }}>
                For journalists, authors, researchers, oral historians and students.
            </Typography>
        </HomepageOutline>
    )
}
